import React from "react";
import Layout from '../../components/Layout'

export default () => (
  <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
                <h1>Thnx!</h1>
                <p>Ik kom zo spoedig mogelijk op je bericht terug</p>
            </div>
          </div>
          </section>
  </Layout>
);